
















































































































































import {Component, Vue} from 'vue-property-decorator';
import {getCourse, getHomework, postQuesInfo} from '@/api/courseWork/courseWork.ts';
import myHeader from '@/components/Header.vue';
import myFooter from '@/components/Footer.vue';
import {getIndexLive, postIndexLive} from '@/api/liveCourse/liveCourse';

@Component({
  components: {
    myHeader,
    myFooter

  }
})
export default class CourseWork extends Vue {
  // api参数
  data = {
    // eslint-disable-next-line @typescript-eslint/camelcase,no-undef
    semester_id: -1,
    student: []
  };

  loading = true;
  loadingText = '正在加载中...';
  streamAddress = '';
  msgText = '';
  SingInShow = false;
  dialogVisible = false;
  courseList = [];
  homeworkList = [];
  liveList = [];
  semesterList = ['第一学期', '第二学期', '第三学期', '第四学期', '第五学期', '第六学期'];
  semesterStr = '';

  created() {
    const poUrl = localStorage.getItem('loginUrl');
    const loginurl = this.$route.query.url;
    // eslint-disable-next-line eqeqeq
    if (loginurl) {
      // eslint-disable-next-line eqeqeq

      localStorage.setItem('loginUrl', loginurl.toString());
    }
    this.getData();
  }

  // 方法
  getData() {
    // 获取学期id
    // eslint-disable-next-line @typescript-eslint/camelcase
    const studen = JSON.parse(localStorage.getItem('student') as string);
    const checksem = JSON.parse(localStorage.getItem('checkSemester') as string);
    const myToken = localStorage.getItem('myToken') as string;
    if (myToken !== null) {
      if (checksem !== null) {
        this.data.semester_id = parseInt(checksem.id);
        this.semesterStr = studen.semesterList[parseInt(checksem.semester) - 1].stu_date.replace(/\(/g, '(' + this.semesterList[parseInt(checksem.semester) - 1] + ':');
      } else {
        this.data.semester_id = parseInt(localStorage.getItem('semesterId') as string);
        this.semesterStr = studen.semesterList[parseInt(studen.semester) - 1].stu_date.replace(/\(/g, '(' + this.semesterList[parseInt(studen.semester) - 1] + ':');
      }
    }

    // this.data.student = studen;
    // 课程api;
    getCourse(this.data).then((res: any) => {
      if (res.code === 200) {
        this.courseList = res.data;
      } else {
        console.log(res.error);
        this.$message.error(res.error);
      }
      this.loading = false;
    }).catch((error: any) => {
      console.log(error);
    });

    // 作业api
    getHomework(this.data).then((res: any) => {
      if (res.code === 200) {
        this.homeworkList = res.data;
      } else {
        this.$message.error(res.error);
      }
    }).catch((error: any) => {
      console.log(error);
    });

    getIndexLive({}).then((res: any) => {
      if (res.code === 200) {
        this.liveList = res.data;
      } else {
        this.$message.error(res.error);
      }
    });
  };

  // 跳转到课程视频页
  goCourseVideo(crsId: string, teacherName: string, crsName: string, imageUrl: string) {
    this.$router.push({
      path: '/courseVideo',
      query: {
        crsId: crsId,
        teacherName: teacherName,
        crsName: crsName,
        imageUrl: imageUrl
      }
    });
  }

  // 跳转到我的作业
  goMyHomework(crsId: string, crsName: string, totalQues: number) {
    this.$router.push({
      path: '/myHomework',
      query: {
        crsId: crsId,
        crsName: crsName,
        totalQues: totalQues.toString()
      }
    });
  }

  // 跳转到调查问卷
  questionnaire() {
    this.$router.push({
      path: '/questionnaire'
    });
  }

  goLiveSingIn(id: string) {
    this.dialogVisible = true;
    postIndexLive({live_id: id}).then((res: any) => {
      this.loadingText = '';

      if (res.code === 200) {
        this.SingInShow = true;
        this.streamAddress = res.data;
        this.msgText = res.msg;
      } else {
        this.$message.error(res.error);
      }
    });
  }

  handleClose() {
    this.dialogVisible = false;
  }
}

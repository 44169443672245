import { get, post } from '@/utils/http.js';

/*
function:获取直播列表
author:JZ
time:2021/03/24
*/

export function getLiveCourse(data: any) {
    return get('/live/course', data);
}

/*
function:获取直播间
author:JZ
time:2021/03/24
*/

export function getLiveVideo(data: any) {
    return get('/live/video', data);
}

/*
function:获取直播列表
author:YSK
time:2024/04/09
*/

export function getIndexLive(data: any) {
    return get('/live/indexInfo', data);
}

/*
function:获取直播列表
author:YSK
time:2024/04/09
*/

export function postIndexLive(data: any) {
    return post('/live/indexInfo', data);
}

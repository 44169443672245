import { get, post } from '@/utils/http.js';

/*
function:获取课程数据
author:ysl
time:2021/03/16
*/

export function getCourse(data: any) {
    return get('/study/course', data);
}

/*
function:获取作业数据
author:ysl
time:2021/03/16
*/

export function getHomework(data: any) {
    return get('/study/homework/GET', data);
}

/*
function:获取问卷调查列表
author:ysk
time:2024/03/21
*/

export function getQuesList(data: any) {
    return get('/study/questionnaire', data);
}

/*
function:获取问卷调查详情
author:ysk
time:2024/03/21
*/

export function getQuesInfo(data: any) {
    return get('/study/questionnaire/info', data);
}

/*
function:保存调查数据
author:ysk
time:2024/03/21
*/

export function postQuesInfo(data: any) {
    return post('/study/questionnaire/info', data);
}
